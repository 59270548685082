import { AiView } from '@/components/dashboard/settings/location/ai-setup/settings-location-ai-setup-tab-component';
import { ProfileView } from '@/components/dashboard/settings/location/profile/profile-settings';
import { onboardingSteps } from '@/lib/utils/onboardingSteps';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type View = ProfileView | AiView;

export interface OnboardingCompletedOnboardingTabsState {
  completedOnboardingTabs: string[];
  currentStep: string;
  nextStep: string;
}

const initialState: OnboardingCompletedOnboardingTabsState = {
  completedOnboardingTabs: [],
  currentStep: onboardingSteps[0],
  nextStep: onboardingSteps[1]
};

const completedOnboardingTabsSlice = createSlice({
  name: 'completedOnboardingTabs',
  initialState,
  reducers: {
    markOnboardingTabAsCompleted: (state, action: PayloadAction<string>) => {
      const completedTab = action.payload;
      if (!state.completedOnboardingTabs.includes(completedTab)) {
        state.completedOnboardingTabs.push(completedTab);
      }
      const currentIndex = onboardingSteps.indexOf(completedTab);
      if (currentIndex < onboardingSteps.length - 1) {
        state.currentStep = onboardingSteps[currentIndex + 1];
        state.nextStep = onboardingSteps[currentIndex + 2] || '';
      }
    }
  }
});

export const { markOnboardingTabAsCompleted } =
  completedOnboardingTabsSlice.actions;
export default completedOnboardingTabsSlice.reducer;

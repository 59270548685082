import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileTabsState {
  currentView: string;
  completedTabs: string[];
}

const initialState: ProfileTabsState = {
  currentView: '',
  completedTabs: []
};

console.log('Initial State in profileTabsSlice:', initialState);

const profileTabsSlice = createSlice({
  name: 'profileTabs',
  initialState,
  reducers: {
    setCurrentView: (state, action: PayloadAction<string>) => {
      state.currentView = action.payload;
    },
    setInitialView: (state, action: PayloadAction<string>) => {
      if (state.currentView === '') {
        state.currentView = action.payload;
      }
    },
    markTabAsCompleted: (state, action: PayloadAction<string>) => {
      console.log('Action Payload in markTabAsCompleted:', action.payload);
      if (
        state.completedTabs &&
        !state.completedTabs.includes(action.payload)
      ) {
        state.completedTabs.push(action.payload);
      }
      console.log('Updated State in markTabAsCompleted:', state);
    }
  }
});

export const { setCurrentView, setInitialView, markTabAsCompleted } =
  profileTabsSlice.actions;
export default profileTabsSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AiTrainingTextInputsState {
  productServices: string;
  aboutBusiness: string;
  cautionsWarnings: string;
  additionalInformation: string;
}

const initialState: AiTrainingTextInputsState = {
  productServices: '',
  aboutBusiness: '',
  cautionsWarnings: '',
  additionalInformation: ''
};

const aiTrainingTextInputsSlice = createSlice({
  name: 'aiTrainingTextInputs',
  initialState,
  reducers: {
    setProductServices: (state, action: PayloadAction<string>) => {
      state.productServices = action.payload;
    },
    setAboutBusiness: (state, action: PayloadAction<string>) => {
      state.aboutBusiness = action.payload;
    },
    setCautionsWarnings: (state, action: PayloadAction<string>) => {
      state.cautionsWarnings = action.payload;
    },
    setAdditionalInformation: (state, action: PayloadAction<string>) => {
      state.additionalInformation = action.payload;
    }
  }
});

export const {
  setProductServices,
  setAboutBusiness,
  setCautionsWarnings,
  setAdditionalInformation
} = aiTrainingTextInputsSlice.actions;

export default aiTrainingTextInputsSlice.reducer;

import { Assignment } from '@/lib/state/slices/auth-slice';
import { createSlice } from '@reduxjs/toolkit';

export interface Message {
  user_id?: number;
  customer_store_id?: number;
  title?: string;
  active_assignments?: Assignment[];
  body?: string;
  direction?: string;
  from_number?: string;
  channel?: string;
  customer_name?: string;
  auto_respond?: boolean;
  error?: boolean;
  id?: number;
  from_email?: string;
  auto_reply?: boolean;
  sent_at?: string;
  exception?: string;
  failed_at?: string;
  status?: string;
}

export const notificationBellSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0 as number,
    messages: [] as Message[]
  },
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    reset: (state) => {
      state.value = 0;
    },
    addMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    removeMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message, i) => i !== action.payload
      );
    },
    clearMessages: (state) => {
      state.messages = [];
    }
  }
});

export const { increment, reset, addMessage, removeMessage, clearMessages } =
  notificationBellSlice.actions;

export default notificationBellSlice.reducer;

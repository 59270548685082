import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsAiSetupTabsState {
  currentView: string;
}

const initialState: SettingsAiSetupTabsState = {
  currentView: 'ai-communication-preferences'
};

const settingsAiSetupTabsSlice = createSlice({
  name: 'settingsAiSetupTabs',
  initialState,
  reducers: {
    setCurrentView: (state, action: PayloadAction<string>) => {
      state.currentView = action.payload;
    }
  }
});

export const { setCurrentView } = settingsAiSetupTabsSlice.actions;
export default settingsAiSetupTabsSlice.reducer;
